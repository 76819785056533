import { useSignal, Signal } from "@preact/signals-react";
import React, { useEffect } from "react";

export default class Watcher {
  signal: Signal<null | Watcher>;
  promise: Promise<any>;
  start: number;
  constructor(signal: Signal<null | Watcher>, promise: Promise<any>) {
    this.signal = signal;
    this.promise = promise;
    this.start = Date.now();
    this.Timer = this.Timer.bind(this);
    this.TimerMessage = this.TimerMessage.bind(this);
  }

  static watch(signal: Signal<null | Watcher>, promise: Promise<any>) {
    if (signal.value) {
      console.warn("Overwriting existing Watcher");
      throw new Error("Overwriting existing Watcher");
      // return null;
    }
    const w = new Watcher(signal, promise);
    w.signal.value = w;
    promise.finally(() => {
      if (signal.value === w) {
        signal.value = null;
      }
    });
    return promise;
  }

  Timer() {
    // Despite what eslint thinks, this isn't a class component
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const time = useSignal(Date.now() - this.start);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const id = setInterval(() => {
        time.value = Date.now() - this.start;
      }, 1000);
      return () => {
        clearInterval(id);
      };
    }, [time]);
    return <code>{this.formatTime(time.value)}</code>;
  }

  TimerMessage({ message }: { message: React.ReactNode }) {
    return (
      <div className="flex items-center justify-center m-1">
        <div className="rounded-md inline-block border-orange-700 border-2 bg-orange-600 text-white p-2">
          {message} <this.Timer />
        </div>
      </div>
    );
  }

  static Filler({ message }: { message: React.ReactNode }) {
    return (
      <div className="flex items-center justify-center m-1">
        <div className="rounded-md inline-block border-orange-700 border-2 bg-white text-black p-2">
          {message}
        </div>
      </div>
    );
  }

  formatTime(time: number) {
    const minutes = Math.floor(time / 1000 / 60);
    const seconds = Math.floor((time / 1000) % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  }
}
