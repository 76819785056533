import React, { useRef, useEffect, useCallback } from "react";
import { Signal } from "@preact/signals-react";
import { twMerge } from "tailwind-merge";

export default function Popup({
  className,
  children,
  showSignal,
}: {
  className?: string;
  children: React.ReactNode;
  showSignal: Signal<boolean>;
}) {
  const popupRef = useRef<HTMLDivElement>(null);
  const onBackgroundClick = useCallback(
    (e: MouseEvent) => {
      if (!popupRef.current) {
        return;
      }
      const el = popupRef.current as HTMLDivElement;
      if (popupRef.current && !el.contains(e.target as HTMLElement) && showSignal.value) {
        showSignal.value = false;
      }
    },
    [showSignal],
  );
  const onKeyboard = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        showSignal.value = false;
      }
    },
    [showSignal],
  );
  useEffect(() => {
    document.addEventListener("click", onBackgroundClick);
    document.addEventListener("keydown", onKeyboard);
    return () => {
      document.removeEventListener("click", onBackgroundClick);
      document.removeEventListener("keydown", onKeyboard);
    };
  }, [showSignal, onBackgroundClick, onKeyboard]);
  if (!showSignal.value) {
    return null;
  }
  const cls = twMerge(
    "z-10 p-1 absolute right-0 top-8 w-1/2 bg-white text-black rounded-lg shadow-xl",
    className,
  );
  return (
    <div className={cls} ref={popupRef}>
      <button
        onClick={() => {
          showSignal.value = false;
        }}
        className="float-right text-gray-500 hover:text-black cursor-pointer mr-2"
      >
        ×
      </button>
      <div className="p-4">{children}</div>
    </div>
  );
}
