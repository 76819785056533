import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import Link from "next/link";
import { models, defaultModelSignal, showUnrecommendedModelsSignal } from "@/lib/llm";
import { twMerge } from "tailwind-merge";
import { setUser, User, UserSessionContext } from "@/lib/user";
import { AbstractSession, loadSession } from "@/lib/abstractsession";
import { ParsedUrlQuery } from "querystring";
import Select from "./select";
import { DefinitionButton } from "./definition";
import AuthMenu from "./authmenu";

type StartProps = {
  session?: AbstractSession;
  init?: (_query: ParsedUrlQuery) => Promise<void>;
  [key: string]: any;
};

// eslint-disable-next-line no-empty-pattern
export function start(
  startProps: StartProps,
  Component: (_props: any) => React.ReactNode,
): ({ user, ...props }: { user: User; props: any }) => React.ReactNode {
  const result = ({ user, ...props }: { user: User; props: any }) => {
    const { session, init } = startProps;
    setUser(user);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const router = useRouter();
    const { sessionId } = router.query;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [sessionReady, setSessionReady] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [initReady, setInitReady] = useState(!init);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (sessionId && session) {
        if (session.unloaded) {
          loadSession(session, sessionId as string).then(() => {
            setSessionReady(true);
          });
        } else if (!sessionReady) {
          setSessionReady(true);
        }
      } else {
        // FIXME: this is silly, but is due to the thing not loading immediately
        setSessionReady(true);
      }
    }, []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (router.isReady && init && !initReady && !session?.unloaded) {
        init(router.query)
          .then(() => {
            setInitReady(true);
          })
          .catch((e) => {
            console.error("Failed to init:", e);
            // FIXME: should have error state here:
            setInitReady(true);
          });
      }
    }, [router.isReady, session?.unloaded]);
    if ((router.pathname.includes("[sessionId]") && !sessionId) || (init && !initReady)) {
      // Why doesn't this load immediately?
      return (
        <UserSessionContext.Provider value={null}>
          <Page title="Loading..." suppressControls suppressAuth>
            <PageMessage>Loading...</PageMessage>
          </Page>
        </UserSessionContext.Provider>
      );
    }
    if (!sessionReady) {
      return (
        <UserSessionContext.Provider value={null}>
          <Page title="Loading..." suppressControls suppressAuth>
            <PageMessage>Loading...</PageMessage>
          </Page>
        </UserSessionContext.Provider>
      );
    } else {
      return (
        <UserSessionContext.Provider value={user}>
          <Component {...props} />
        </UserSessionContext.Provider>
      );
    }
  };
  result.displayName = "start";
  return result;
}

export function Page({
  title,
  getContext,
  suppressControls,
  suppressAuth,
  backgroundImage,
  backgroundCover,
  children,
}: {
  title: string;
  getContext?: () => string;
  suppressControls?: boolean;
  suppressAuth?: boolean;
  backgroundImage?: string;
  backgroundCover?: boolean;
  children: React.ReactNode;
}) {
  useEffect(() => {
    document.title = title;
  }, [title]);
  let childrenList: React.ReactNode[];
  if (!Array.isArray(children)) {
    childrenList = [children];
  } else {
    childrenList = children;
  }
  const freeChunks = childrenList.filter((x) => (x as any)?.type?.isFreeChunk);
  childrenList = childrenList.filter((x) => x).filter((x) => !(x as any)?.type?.isFreeChunk);
  const count = Array.isArray(children) ? children.length : 1;
  let startWidth = "";
  let restWidth = "";
  if (count === 4) {
    startWidth = "w-1/4";
    restWidth = "w-1/4";
  } else if (count === 3) {
    startWidth = "w-1/2";
    restWidth = "w-1/4";
  } else if (count === 2) {
    startWidth = "w-2/3";
    restWidth = "w-1/3";
  } else {
    startWidth = "w-full";
  }
  const mainStyle: any = {};
  if (backgroundImage) {
    mainStyle.backgroundImage = `url("${backgroundImage}")`;
    if (backgroundCover) {
      mainStyle.backgroundRepeat = "no-repeat";
      mainStyle.backgroundSize = "cover";
    } else {
      mainStyle.backgroundRepeat = "repeat";
    }
  }
  return (
    <div className="flex flex-col h-screen">
      <Header
        title={`NOT PUBLIC ${title}`}
        getContext={getContext}
        suppressControls={suppressControls}
        suppressAuth={suppressAuth}
      />
      <main className="flex flex-grow overflow-auto bg-gray-300" style={mainStyle}>
        {childrenList.map((child, i) => {
          let cls = `bg-gray-100 p-4 overflow-auto m-2 rounded-lg shadow-xl ${
            i === 0 ? startWidth : restWidth
          }`;
          if (child && (child as any).type?.isFullTabs) {
            cls = `overflow-auto ${i === 0 ? startWidth : restWidth}`;
          }
          return (
            <div key={i} className={cls}>
              {child}
            </div>
          );
        })}
        {freeChunks}
      </main>
    </div>
  );
}

function Header({
  title,
  getContext,
  suppressControls,
  suppressAuth,
}: {
  title: string;
  getContext?: () => string;
  suppressControls?: boolean;
  suppressAuth?: boolean;
}) {
  let showModels = models;
  if (!showUnrecommendedModelsSignal.value) {
    showModels = showModels.filter((x) => x.recommended);
  }
  const options = showModels.map((m) => ({ label: m.name, value: m.id }));
  return (
    <header className="bg-blue-900 text-white py-1 px-2 height-4 flex justify-between items-center border-b-2 border-gray-300">
      <h1 className="text-lg font-semibold">
        <Link href="/">
          <img src="/favicon.png" alt="Home" className="h-4 w-4 mr-2 inline-block" />
        </Link>
        {title}
      </h1>
      <div>
        {!suppressControls && (
          <>
            <DefinitionButton getContext={getContext} />
            <Select
              className="text-black text-xs bg-blue-200 rounded-md"
              options={options}
              signal={defaultModelSignal}
            />
          </>
        )}
        {!suppressAuth && <AuthMenu className="ml-2" />}
      </div>
    </header>
  );
}

export const PageMessage = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  className = twMerge("m-auto bg-white m-4 p-4 rounded-lg shadow-xl", className);
  return (
    <div className="w-full min-h-screen flex items-center justify-center">
      <div className={className}>{children}</div>
    </div>
  );
};

PageMessage.isFreeChunk = true;

export function FreeChunk({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

FreeChunk.isFreeChunk = true;

export const TransparentBackground = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  if (className) {
    return <div className={className}>{children}</div>;
  }
  return <>{children}</>;
};

TransparentBackground.isFullTabs = true;
