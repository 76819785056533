import { signIn, signOut } from "next-auth/react";
import { useUserSession, User } from "@/lib/user";
import { useSignal } from "@preact/signals-react";
import { twMerge } from "tailwind-merge";
import Button from "./button";
import Popup from "./popup";
import { LoginIcon, AvatarIcon } from "./miscicons";

export default function AuthMenu({ className }: { className?: string }) {
  const user = useUserSession();
  // const { data: userSession } = useSession();
  const popupSignal = useSignal(false);
  const cls = twMerge("inline-block", className);
  let avatar = <LoginIcon className="h-3 w-3 text-white" />;
  if (user) {
    avatar = user.image ? (
      <img
        src={user.image}
        className="h-4 w-4 rounded-full inline-block p-0 m-0"
        alt="Sign-in status"
      />
    ) : (
      <AvatarIcon className="h-4 w-4 text-white" />
    );
  }
  return (
    <div className={cls}>
      <button
        className="rounded-full bg-white text-black p-0.5"
        onClick={(e) => {
          e.stopPropagation();
          popupSignal.value = !popupSignal.value;
        }}
      >
        {avatar}
      </button>
      <Popup showSignal={popupSignal} className="w-48">
        <AuthDetail user={user} />
      </Popup>
    </div>
  );
}

function AuthDetail({ user }: { user: User | null }) {
  if (!user) {
    return (
      <div>
        <div>You are not signed in</div>
        <Button onClick={() => signIn()}>Sign in</Button>
      </div>
    );
  }
  return (
    <div className="text-sm">
      <div className="text-sm">Signed in as:</div>
      <div className="ml-2">{user?.email}</div>
      <ul className="mt-2">
        <li className="my-2">
          <a className="font-semibold hover:underline hover:bg-gray-100 rounded py-2" href="/about">
            About
          </a>
        </li>
        <li className="my-2">
          <a className="font-semibold hover:underline hover:bg-gray-100 rounded py-2" href="/tos">
            Terms &amp; Privacy
          </a>
        </li>
        <li className="my-2">
          <a
            className="font-semibold hover:underline hover:bg-gray-100 rounded py-2"
            href="/settings"
          >
            Settings
          </a>
        </li>
        <li className="my-2">
          <button
            className="font-semibold hover:underline hover:bg-gray-100 rounded py-2"
            onClick={() => signOut()}
          >
            Sign out
          </button>
        </li>
      </ul>
    </div>
  );
}
