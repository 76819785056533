import getConfig from "next/config";
import { Validator } from "@cfworker/json-schema";
import { signal, Signal } from "@preact/signals";
import JSON5 from "json5";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";
import { dedent } from "./template";
import { persistentSignal } from "./persistentsignal";
import { getCachedUserKeys, purgeCachedUserKeys } from "./userkeys";

const DEFAULT_MODEL = getConfig()?.publicRuntimeConfig?.DEFAULT_MODEL || "gpt-3.5-turbo-1106";

type ModelDescription = {
  name: string;
  id: string;
  // Cents per 1k tokens
  inputTokenCost: number;
  outputTokenCost: number;
  backend: "openai" | "google" | "openrouter";
  openrouterId: string;
  functionResponse: boolean;
  recommended: boolean;
  maxTokens?: number;
};

export const models: ModelDescription[] = [
  {
    name: "GPT-3.5 Turbo",
    id: "gpt-3.5-turbo",
    inputTokenCost: 0.1,
    outputTokenCost: 0.2,
    backend: "openai",
    openrouterId: "openai/gpt-3.5-turbo",
    functionResponse: true,
    recommended: true,
  },
  {
    name: "GPT-4",
    id: "gpt-4",
    inputTokenCost: 3,
    outputTokenCost: 6,
    backend: "openai",
    openrouterId: "openai/gpt-4",
    functionResponse: true,
    recommended: false,
  },
  {
    name: "GPT-4 Turbo",
    id: "gpt-4-turbo",
    inputTokenCost: 1, // 1 cent per 1k tokens
    outputTokenCost: 3,
    backend: "openai",
    openrouterId: "openai/gpt-4-turbo",
    functionResponse: true,
    recommended: true,
  },
  {
    name: "Gemini Pro",
    id: "gemini-pro",
    inputTokenCost: 0,
    outputTokenCost: 0,
    backend: "google",
    openrouterId: "google/gemini-pro",
    functionResponse: true,
    recommended: true,
  },
  {
    name: "Mistral 7B Instruct",
    id: "mistral-7b-instruct",
    inputTokenCost: 0.013,
    outputTokenCost: 0.013,
    backend: "openrouter",
    openrouterId: "mistralai/mistral-7b-instruct",
    functionResponse: false,
    recommended: false,
  },
  {
    name: "Mistral 7B Instruct (Free)",
    id: "mistral-7b-instruct:free",
    inputTokenCost: 0,
    outputTokenCost: 0,
    backend: "openrouter",
    openrouterId: "mistralai/mistral-7b-instruct:free",
    functionResponse: false,
    recommended: false,
  },
  {
    name: "MythoMax 13B",
    id: "mythomax-l2-13b",
    inputTokenCost: 0.0225,
    outputTokenCost: 0.0225,
    backend: "openrouter",
    openrouterId: "gryphe/mythomax-l2-13b",
    functionResponse: false,
    recommended: false,
  },
  {
    name: "Nous: Hermes 2 Mixtral 8x7B DPO",
    id: "nous-hermes-2-mixtral-8x7b-dpo",
    inputTokenCost: 0.03,
    outputTokenCost: 0.03,
    backend: "openrouter",
    openrouterId: "nousresearch/nous-hermes-2-mixtral-8x7b-dpo",
    functionResponse: false,
    recommended: false,
  },
  {
    name: "Toppy M 7B",
    id: "toppy-m-7b",
    inputTokenCost: 0.018,
    outputTokenCost: 0.018,
    backend: "openrouter",
    openrouterId: "undi95/toppy-m-7b",
    functionResponse: false,
    recommended: false,
  },
  {
    name: "Anthropic: Claude v2.1",
    id: "claude-2",
    inputTokenCost: 0.8,
    outputTokenCost: 0.8,
    backend: "openrouter",
    openrouterId: "anthropic/claude-2",
    functionResponse: false,
    recommended: false,
  },
  {
    name: "Claude Opus $$",
    id: "claude-3-opus",
    inputTokenCost: 1.5,
    outputTokenCost: 7.5,
    backend: "openrouter",
    openrouterId: "anthropic/claude-3-opus",
    functionResponse: false,
    recommended: true,
  },
  {
    name: "Claude Opus Beta $$",
    id: "claude-3-opus:beta",
    inputTokenCost: 1.5,
    outputTokenCost: 7.5,
    backend: "openrouter",
    openrouterId: "anthropic/claude-3-opus:beta",
    functionResponse: false,
    recommended: false,
  },
  {
    name: "Claude Sonnet",
    id: "claude-3-sonnet",
    inputTokenCost: 0.3,
    outputTokenCost: 1.5,
    backend: "openrouter",
    openrouterId: "anthropic/claude-3-sonnet",
    functionResponse: false,
    recommended: true,
  },
  {
    name: "Claude Sonnet Beta",
    id: "claude-3-sonnet:beta",
    inputTokenCost: 0.3,
    outputTokenCost: 1.5,
    backend: "openrouter",
    openrouterId: "anthropic/claude-3-sonnet:beta",
    functionResponse: false,
    recommended: false,
  },
  {
    name: "Midnight Rose 70B",
    id: "midnight-rose-70b",
    inputTokenCost: 0.9,
    outputTokenCost: 0.9,
    backend: "openrouter",
    openrouterId: "sophosympatheia/midnight-rose-70b",
    functionResponse: false,
    recommended: false,
  },
];

const _modelMap = new Map(models.map((x) => [x.id, x]));

export function getModel(id: string) {
  return _modelMap.get(id) || models[0];
}

export const defaultModelSignal = persistentSignal("llm-default-model", DEFAULT_MODEL);

export const showUnrecommendedModelsSignal = persistentSignal("llm-show-unrecommended", false);

export interface LlmPromptMessage {
  role: "user" | "assistant" | "system" | "function";
  name?: string | undefined;
  content?: string | undefined;
  function_call?:
    | {
        name: string;
        arguments: string;
      }
    | undefined;
}

interface LlmPrompt {
  title?: string;
  messages: LlmPromptMessage[];
  temperature?: number | undefined;
  max_tokens?: number | undefined;
  model?: "gpt-3.5-turbo" | "gpt-3.5-turbo-1106" | "gpt-4" | "gpt-4-1106-preview" | "gemini-pro";
  tools?: LlmPromptTool[];
  tool_choice?: "none" | "auto" | LlmPromptToolChoice;
  validate?: (_response: LlmResponse) => boolean;
}

interface LlmPromptTool {
  type: "function";
  function: {
    name: string;
    description?: string;
    parameters: any;
  };
}

interface LlmPromptToolChoice {
  type: "function";
  function: { name: string };
}

interface LlmJsonResponse {
  choices: LlmJsonResponseChoices[];
  actual_model?: string;
  usage?: {
    completion_tokens: number;
    prompt_tokens: number;
    total_tokens: number;
  };
}

interface LlmJsonResponseChoices {
  message: LlmJsonResponseMessage;
  // FIXME: specify exact strings:
  finish_reason: string;
}

interface LlmJsonResponseMessage {
  role: "user" | "assistant" | "system" | "function";
  name: string | undefined;
  content: string | undefined;
  tool_calls?: LlmJsonResponseToolCall[];
}

interface LlmJsonResponseToolCall {
  type: "function";
  function: {
    name: string;
    id?: string;
    arguments: string;
    argumentsParsed?: any;
  };
}

export class LlmResponse {
  prompt: LlmPrompt;
  title?: string;
  json: LlmJsonResponse;
  responseNumber: number;
  start: number;
  end: number;
  expanded: Signal<boolean | null>;
  retries: number = 0;
  retryReasons: RetryReason[] = [];
  version: Signal<number>;
  constructor({
    prompt,
    title,
    json,
    responseNumber,
    start,
    end,
    retries,
    retryReasons,
  }: {
    prompt: LlmPrompt;
    title?: string;
    json: LlmJsonResponse;
    responseNumber: number;
    start: number;
    end: number;
    retries?: number;
    retryReasons?: RetryReason[];
  }) {
    this.prompt = prompt;
    this.title = title;
    this.json = json;
    this.responseNumber = responseNumber;
    this.start = start;
    this.end = end;
    this.expanded = signal(null);
    this.retries = retries || 0;
    this.retryReasons = retryReasons || [];
    this.version = signal(0);
  }

  get isFunctionCall() {
    return !!this.json.choices[0].message.tool_calls;
  }

  get content() {
    const m = this.json.choices[0].message;
    if (m.tool_calls && !m.content) {
      throw new Error(`No content in response (tool_calls: ${JSON.stringify(m.tool_calls)}())`);
    }
    return m.content;
  }

  get functionArguments() {
    const m = this.json.choices[0].message;
    const model = getModel(this.json.actual_model || this.prompt.model!);
    if (!m.tool_calls && !model.functionResponse) {
      return JSON.parse(this.content!);
    }
    // Excpected, so
    if (!m.tool_calls) {
      throw new Error(`No tools calls in response`);
    }
    if (m.tool_calls.length !== 1) {
      throw new Error(`Expected exactly one tool call in response`);
    }
    if (m.tool_calls[0].type !== "function") {
      throw new Error(`Expected tool call to be a function`);
    }
    return m.tool_calls[0].function.argumentsParsed;
  }

  get parsed() {
    if (!this.content) {
      return null;
    }
    let content = this.content.trim();
    content = content.replace(/^`+/, "").replace(/`+$/, "").trim();
    const bracket = content.indexOf("[");
    const brace = content.indexOf("{");
    if (bracket === -1) {
      if (brace !== -1) {
        content = content.slice(brace);
      }
    } else if (brace === -1) {
      if (bracket !== -1) {
        content = content.slice(bracket);
      }
    } else if (bracket < brace) {
      content = content.slice(bracket);
    } else {
      content = content.slice(brace);
    }
    return JSON5.parse(content);
  }

  get finishReason() {
    return this.json.choices[0].finish_reason;
  }

  get time() {
    if (!this.end) {
      return null;
    }
    return this.end - this.start;
  }

  validationErrors(): null | string[] {
    /* Test if the function response is valid given the prompt schema */
    const { tools } = this.prompt;
    const { validate } = this.prompt;
    if (validate) {
      try {
        const result = validate(this);
        if (!result) {
          return ["validate() failed"];
        }
      } catch (e) {
        console.warn("Got an error from the validate:", e, validate);
        return [(e as any).toString()];
      }
    }
    if (!tools?.length) {
      return null;
    }
    const m = this.json.choices[0].message;
    if (!m.tool_calls) {
      return null;
    }
    if (m.tool_calls.length !== 1) {
      console.warn(
        "Will only check first tool call, though there are multiple calls:",
        m.tool_calls,
      );
    }
    const result = m.tool_calls[0].function.argumentsParsed;
    const functionName = m.tool_calls[0].function.name;
    const tool = tools.find((t) => t.function?.name === functionName);
    if (!tool) {
      console.warn("No tool found for function:", functionName);
      return null;
    }
    const schema = tool.function.parameters;
    const validator = new Validator(schema, "4", true);
    const validationResult = validator.validate(result);
    if (validationResult.valid) {
      return null;
    }
    return validationResult.errors.map((e) => e.error);
  }

  cost() {
    const model = this.json.actual_model || this.prompt.model || defaultModelSignal.value;
    const { inputTokenCost, outputTokenCost } = getModel(model);
    if (inputTokenCost === 0 && outputTokenCost === 0) {
      return 0;
    }
    const { completion_tokens, prompt_tokens } = this.json.usage || {};
    if (completion_tokens === undefined || prompt_tokens === undefined) {
      console.warn("No usage information in response");
      return 0;
    }
    const cents = (completion_tokens * outputTokenCost + prompt_tokens * inputTokenCost) / 1000;
    return cents;
  }
}

let responseNumber = 1;

type RetryReason = "validation" | "500";

const retryEmoji: {
  [_key in RetryReason]: string;
} = {
  validation: "🚫",
  "500": "💥",
};

class PendingLlmResponse {
  prompt: LlmPrompt;
  title?: string;
  responseNumber: number;
  start: number = -1;
  end: number = -1;
  failure: any = null;
  version: Signal<number>;
  retries: number = 0;
  retryReasons: RetryReason[] = [];
  expanded: Signal<boolean | null>;
  constructor({ prompt, title }: { prompt: LlmPrompt; title?: string }) {
    this.prompt = prompt;
    this.title = title;
    this.responseNumber = responseNumber++;
    this.version = signal(0);
    this.expanded = signal(true);
  }

  addRetryReason(reason: RetryReason) {
    this.retries++;
    this.retryReasons.push(reason);
    this.version.value++;
  }

  withJson(json: LlmJsonResponse) {
    return new LlmResponse({
      prompt: this.prompt,
      title: this.title,
      json,
      responseNumber: this.responseNumber,
      start: this.start,
      end: this.end,
      retries: this.retries,
      retryReasons: this.retryReasons,
    });
  }
}

type LlmLogItem = LlmResponse | PendingLlmResponse;

export class LLM {
  logs: Signal<LlmLogItem[]>;
  constructor() {
    this.logs = signal([]);
    this.Log = this.Log.bind(this);
  }

  async chat(prompt: LlmPrompt) {
    if (prompt.temperature === undefined || prompt.temperature === null) {
      prompt.temperature = 0.1;
    }
    const { title } = prompt;
    delete prompt.title;
    const pendingResponse = new PendingLlmResponse({ prompt, title });
    this.logs.value = [pendingResponse, ...this.logs.value];
    pendingResponse.start = Date.now();
    prompt.model = prompt.model || defaultModelSignal.value;
    console.info("Sending LLM request:", prompt);
    let resp;
    // eslint-disable-next-line no-unreachable-loop
    let response: LlmResponse;
    let json: any;
    while (true) {
      resp = await fetchWithAuth(prompt);
      pendingResponse.end = Date.now();
      if (!resp.ok) {
        let shouldRetry = false;
        if (resp.status === 500 && prompt.model === "gemini-pro") {
          shouldRetry = true;
        }
        if (resp.status === 429 || resp.status === 504 || resp.status === 529) {
          shouldRetry = true;
        }
        if (shouldRetry) {
          if (pendingResponse.retries >= 3) {
            let msg: any = `${resp.status} ${resp.statusText}`;
            try {
              const errorJson = await resp.json();
              msg = errorJson.error.message;
            } catch (e) {
              // It's fine, do nothing...
            }
            pendingResponse.failure = msg;
            pendingResponse.version.value++;
            throw new Error(`ChatGPT request failed`);
          }
          pendingResponse.addRetryReason("500");
          const errorBody = await resp.text();
          console.warn(
            `Got ${resp.status} from ${prompt.model}, trying again (retry ${pendingResponse.retries}): retries:`,
            errorBody,
          );
          if (prompt.model === "gpt-4-1106-preview" && pendingResponse.retries >= 2) {
            prompt.model = "gpt-3.5-turbo-1106";
            console.warn("Trying again with model:", prompt.model);
          }
          continue;
        }
        const body = await resp.json();
        console.error("Error from ChatGPT:", body);
        const exc = new Error(
          `ChatGPT request failed: ${resp.status} ${resp.statusText}: ${body.error.message}`,
        );
        pendingResponse.failure = body;
        pendingResponse.version.value++;
        throw exc;
      }
      json = await resp.json();
      if (json.error) {
        console.error("Error from LLM:", json);
        throw new Error(`Error from model: ${JSON.stringify(json.error)}`);
      }
      if (!json.choices) {
        console.error("Response has no choices:", json);
        pendingResponse.addRetryReason("validation");
        // FIXME: should test for retry limit here
        if (pendingResponse.retries >= 3) {
          throw new Error("Could not get response");
        }
        continue;
      }
      parseArguments(json);
      response = pendingResponse.withJson(json);
      const validationErrors = response.validationErrors();
      if (validationErrors) {
        console.info(
          "Response had validation errors:",
          validationErrors,
          response.isFunctionCall ? response.functionArguments : response.content,
        );
        if (pendingResponse.retries >= 3) {
          break;
        }
        pendingResponse.addRetryReason("validation");
        continue;
      }
      break;
    }
    this.logs.value = this.logs.value.map((item) => (item === pendingResponse ? response : item));
    console.info(
      "Got ChatGPT response:",
      json,
      response.isFunctionCall ? response.functionArguments : response.content,
    );
    return response;
  }

  Log({ noHeader }: { noHeader?: boolean }) {
    return (
      <div data-define-context="This is a log of prompts that are sent to GPT or an LLM; they represent the internal workings of the game">
        {!noHeader && <h1 className="bg-blue-900 text-white -mt-4 -mx-4 px-4">LLM Logs </h1>}
        {this.logs.value.map((response, i) => {
          return <LogItem key={i} response={response} index={i} />;
        })}
      </div>
    );
  }
}

const LogItem = ({ response, index }: { response: LlmLogItem; index: number }) => {
  const _ = response.version.value;
  const time = response instanceof LlmResponse ? response.time : null;
  const retryReasons = response.retryReasons ?? [];
  let inner;
  let expanded = false;
  if (response instanceof LlmResponse) {
    expanded = response.expanded.value || (response.expanded.value === null && index === 0);
    if (expanded) {
      inner = <LogItemLlmResponse response={response} />;
    }
  } else if (response instanceof PendingLlmResponse && response.expanded.value) {
    inner = <LogItemPendingLlmResponse response={response} />;
  }
  let cost;
  if (response instanceof LlmResponse) {
    cost = response.cost();
    if (!cost) {
      cost = " $0";
    } else {
      cost = ` $${(cost / 100).toFixed(3)}`;
    }
  }
  const actualModel =
    (response instanceof LlmResponse ? response.json.actual_model : null) ||
    response.prompt.model ||
    defaultModelSignal.value;
  return (
    <div className="mb-2">
      <div
        className="bg-gray-200 p-1 pl-2 mb-2 -mr-3 rounded-l text-xs cursor-default"
        onClick={() => {
          response.expanded.value = !expanded;
        }}
      >
        {response.title && <div className="float-right mr-1 monospace">{response.title}</div>}
        {response.expanded.value ? (
          <MinusCircleIcon className="h-3 w-3 inline-block mr-2" />
        ) : (
          <PlusCircleIcon className="h-3 w-3 inline-block mr-2" />
        )}
        #{response.responseNumber} {" – "}
        {time ? `${(time / 1000).toFixed(1)}s` : "... running"}
        {cost ? <span className="ml-2">{cost}</span> : null}
        {actualModel !== DEFAULT_MODEL ? (
          <strong className="ml-2 text-amber-600">{actualModel}</strong>
        ) : null}
        {retryReasons.length ? (
          <span> {retryReasons.map((x) => retryEmoji[x]).join("")}</span>
        ) : null}
      </div>
      {inner}
    </div>
  );
};

const LogItemPendingLlmResponse = ({ response }: { response: PendingLlmResponse }) => {
  return (
    <div className="bg-blue-100">
      <LogItemMessages messages={response.prompt.messages} />
      {response.failure ? (
        <pre className="text-xs whitespace-pre-wrap pl-6 -indent-4 bg-red-200">
          {JSON.stringify(response.failure, null, 2)}
        </pre>
      ) : null}
    </div>
  );
};

const LogItemMessages = ({
  messages,
  className,
}: {
  messages: LlmPromptMessage[];
  className?: string;
}) => {
  className = twMerge("text-xs whitespace-pre-wrap pl-6 -indent-4", className);
  return (
    <>
      {messages.map((message, i) => {
        return (
          <pre key={i} className={className}>
            <strong>{message.role}: </strong>
            {message.content?.trim()}
          </pre>
        );
      })}
    </>
  );
};

const LogItemResponseMessage = ({
  message,
  className,
}: {
  message: LlmJsonResponseMessage;
  className?: string;
}) => {
  className = twMerge("text-xs whitespace-pre-wrap pl-6 -indent-4 bg-green-200", className);
  let content = message.content?.trim() || "";
  if (message.tool_calls) {
    for (const tool_call of message.tool_calls) {
      if (tool_call.type !== "function") {
        console.warn("Unexpected tool call:", tool_call);
        continue;
      }
      if (content) {
        content += "\n";
      }
      content += `${tool_call.function.name}(${JSON.stringify(
        tool_call.function.argumentsParsed,
        null,
        2,
      )})`;
    }
  }
  return (
    <pre className={className}>
      <strong>{message.role}: </strong>
      {content}
    </pre>
  );
};

const LogItemLlmResponse = ({ response }: { response: LlmResponse }) => {
  return (
    <div>
      <LogItemMessages messages={response.prompt.messages} />
      <LogItemResponseMessage message={response.json.choices[0].message} />
    </div>
  );
};

// Example error message:
/*
"This model's maximum context length is 4097 tokens. However, you requested
4107 tokens (3607 in the messages, 500 in the completion).
Please reduce the length of the messages or completion."
*/

function parseArguments(json: any) {
  if (!json.choices) {
    console.warn("Response has no choices:", json);
    return;
  }
  for (const c of json.choices) {
    if (c.message.tool_calls) {
      for (const tc of c.message.tool_calls) {
        if (tc.type === "function") {
          tc.function.argumentsParsed = JSON5.parse(tc.function.arguments);
        }
      }
    }
  }
}

export const llm = new LLM();

export function toolOutput({
  name,
  description,
  ...parameters
}: {
  name: string;
  description?: string;
  [key: string]: any;
}): LlmPromptTool[] {
  const actualParameters = {
    type: "object",
    properties: parameters,
  };
  const fixed = fixupParameters(actualParameters);
  if (description) {
    description = dedent(description).trimEnd();
  }
  return [
    {
      type: "function",
      function: {
        name,
        description,
        parameters: fixed,
      },
    },
  ];
}

function fixupParameters(p: any) {
  const newP = { ...p };
  if (p.type === "object" && !p.properties) {
    newP.properties = {};
    for (const [name, v] of Object.entries(p)) {
      if (name === "type" || name === "description" || name === "required") {
        continue;
      }
      newP.properties[name] = v;
      delete newP[name];
    }
  }
  if (newP.type === "object" && !newP.required) {
    newP.required = [];
    for (const [name, v] of Array.from(Object.entries(newP.properties))) {
      if (name.endsWith("*")) {
        const newName = name.slice(0, -1);
        newP.required.push(newName);
        newP.properties[newName] = v;
        delete newP.properties[name];
      }
    }
    if (!newP.required.length) {
      delete newP.required;
    }
  }
  if (newP.type === "object") {
    for (const [name, v] of Array.from(Object.entries(newP.properties))) {
      if (typeof v === "string") {
        newP.properties[name] = { type: "string", description: v };
      }
    }
  }
  if (newP.description) {
    newP.description = dedent(newP.description).trimEnd();
  }
  if (newP.properties) {
    for (const [name, v] of Object.entries(newP.properties)) {
      newP.properties[name] = fixupParameters(v);
    }
  } else if (newP.items) {
    newP.items = fixupParameters(newP.items);
  }
  return newP;
}

export function toolChoice(functionName: string): LlmPromptToolChoice {
  return {
    type: "function",
    function: {
      name: functionName,
    },
  };
}

async function fetchWithAuth(body: any) {
  let prompt = body;
  const userKeys = await getCachedUserKeys();
  const model = getModel(prompt.model);
  if (model.maxTokens && prompt.max_tokens && prompt.max_tokens > model.maxTokens) {
    prompt = { ...prompt, max_tokens: model.maxTokens };
  }
  if (userKeys?.openaiKey && model.backend === "openai") {
    return fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userKeys.openaiKey}`,
      },
      body: JSON.stringify(prompt),
    });
  }
  if (userKeys?.openrouterKey || model.backend === "openrouter") {
    purgeCachedUserKeys();
    if (!userKeys?.openrouterKey) {
      throw new Error("Missing Openrouter.ai key");
    }
    const newPrompt = {
      ...prompt,
      model: model.openrouterId,
    };
    console.log("sending this exact prompt to OpenRouter:", newPrompt);
    return fetch("https://openrouter.ai/api/v1/chat/completions", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userKeys.openrouterKey}`,
        "HTTP-Referer": `${location.origin}${location.pathname}`,
        "X-Title": "A Life Lived",
      },
      body: JSON.stringify(newPrompt),
    });
  }
  return fetch("/api/llm/proxy-gpt", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(prompt),
  });
}
