import { Signal, signal, effect } from "@preact/signals-react";
import pako from "pako";

export abstract class AbstractSession {
  unloaded: boolean;
  signal: Signal;
  sessionId: string = "";
  dirtyPromise: Promise<void> | null;
  _loadedFromDatabase: any = null;
  constructor(data: any, unloaded: boolean) {
    this.signal = signal(data);
    this.dirtyPromise = null;
    effect(() => {
      const curValue = this.signal.value;
      if (curValue === this._loadedFromDatabase) {
        return;
      }
      if (this.sessionId) {
        this._save();
      }
    });
    this.unloaded = unloaded;
  }

  async waitForSave() {
    if (this.dirtyPromise) {
      await this.dirtyPromise;
    }
    return new Promise<void>((resolve) => {
      const id = setInterval(() => {
        if (this.dirtyPromise) {
          clearInterval(id);
          this.dirtyPromise.then(() => resolve());
        }
      }, 50);
    });
  }

  async _save() {
    if (!this.sessionId) {
      return;
    }
    if (this.dirtyPromise) {
      this.dirtyPromise = this.dirtyPromise.then(() => this._saveNow());
    } else {
      this.dirtyPromise = this._saveNow();
    }
    const promise = this.dirtyPromise;
    promise.finally(() => {
      if (this.dirtyPromise === promise) {
        this.dirtyPromise = null;
      }
    });
  }

  async _saveNow() {
    const data = this.signal.value;
    try {
      const compressed = pako.gzip(JSON.stringify(data));
      console.log(
        `Saving session: ${Math.floor(JSON.stringify(data).length / 1000)}kb -> ${Math.floor(
          compressed.length / 1000,
        )}kb`,
        data,
      );
      // Convert the compressed data to a Buffer
      const body = Buffer.from(compressed);
      await fetch(`/api/session/update-session?sessionId=${encodeURIComponent(this.sessionId)}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/octet-stream",
        },
        body,
      });
    } catch (e) {
      console.error("Error in session-saving handler:", e);
    }
  }

  load(sessionId: string, data: any) {
    console.log(`Loading session, ${Math.floor(JSON.stringify(data).length / 1000)}kb`);
    this.sessionId = sessionId;
    this._loadedFromDatabase = data;
    this.signal.value = data;
    this.unloaded = false;
  }
}

export class SessionView {
  _session: AbstractSession;
  _propertyName: string;
  _index: number | null;
  constructor(session: AbstractSession, propertyName: string, index: number | null) {
    this._session = session;
    this._propertyName = propertyName;
    this._index = index;
  }

  _set(attrs: any) {
    if (this._index !== null) {
      const oldArray = this._session.signal.value[this._propertyName] || [];
      if (oldArray.length <= this._index) {
        oldArray.length = this._index + 1;
      }
      const oldValue = oldArray[this._index] || {};
      const newValue = {
        ...oldValue,
        ...attrs,
      };
      const newArray = [
        ...oldArray.slice(0, this._index),
        newValue,
        ...oldArray.slice(this._index + 1),
      ];
      this._session.signal.value = {
        ...this._session.signal.value,
        [this._propertyName]: newArray,
      };
    } else {
      this._session.signal.value = {
        ...this._session.signal.value,
        [this._propertyName]: {
          ...(this._session.signal.value[this._propertyName] || {}),
          ...attrs,
        },
      };
    }
  }

  _copyFrom(other: SessionView) {
    this._session.signal.value = {
      ...this._session.signal.value,
      [this._propertyName]: other._get(),
    };
  }

  _get() {
    const val = this._session.signal.value[this._propertyName] || {};
    if (this._index !== null) {
      return val[this._index] || {};
    }
    return val;
  }

  _properties() {
    return this._session.signal.value[this._propertyName];
  }

  clear() {
    if (this._index !== null) {
      const oldArray = this._session.signal.value[this._propertyName] || [];
      const newArray = [...oldArray.slice(0, this._index), ...oldArray.slice(this._index + 1)];
      this._session.signal.value = {
        ...this._session.signal.value,
        [this._propertyName]: newArray,
      };
    } else {
      const v = { ...this._session.signal.value };
      delete v[this._propertyName];
      this._session.signal.value = v;
    }
  }

  exists() {
    const val = this._session.signal.value[this._propertyName];
    if (val === undefined || val === null) {
      return false;
    }
    if (typeof val === "object" && Object.keys(val).length === 0) {
      return false;
    }
    return true;
  }
}

export async function loadSession(session: AbstractSession, sessionId: string) {
  const response = await fetch(
    `/api/session/get-session?sessionId=${encodeURIComponent(sessionId)}`,
  );
  const json = await response.json();
  if (!json.data) {
    session.load(sessionId, {});
  } else {
    session.load(sessionId, json.data);
  }
}
