import React from "react";
import { Signal } from "@preact/signals-react";

export type Option = {
  label: React.ReactNode;
  value: string;
};

function Select({
  label,
  className,
  signal,
  options,
}: {
  label?: string;
  className?: string;
  signal: Signal<string>;
  options: Option[];
}): React.ReactNode {
  function onChange(e: React.ChangeEvent<HTMLSelectElement>) {
    signal.value = e.target.value;
  }
  const sel = (
    <select className={className} value={signal.value} onChange={onChange}>
      {options.map((o, i) => (
        <option key={i} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  );
  if (label) {
    return (
      <label>
        <div className="font-semibold text-sm">{label}</div>
        {sel}
      </label>
    );
  }
  return sel;
}

export default Select;
