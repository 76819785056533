import { useEffect } from "react";
import { useSignal } from "@preact/signals-react";
import { getWordAtPoint, findClosestBlockLevelElement } from "@/lib/findpositiontext";
import { twMerge } from "tailwind-merge";
import { BookOpenIcon } from "@heroicons/react/24/outline";
import { llm } from "@/lib/llm";
import { tmpl } from "@/lib/template";
import Button from "./button";
import Popup from "./popup";
import Watcher from "./watch";
import { Markdown } from "./markdown";

type DefinitionSource = {
  word: string;
  paragraph: string;
  element: HTMLElement;
  contexts: string[];
};

export function DefinitionButton({ getContext }: { getContext?: () => string }) {
  const lookingForClick = useSignal(false);
  const popupOpen = useSignal(false);
  const definitionSource = useSignal<DefinitionSource | null>(null);
  const loadingDefinition = useSignal<Watcher | null>(null);
  const definition = useSignal<string | null>(null);
  useEffect(() => {
    if (lookingForClick.value) {
      const handler = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const parent = findClosestBlockLevelElement(e.target as HTMLElement);
        const word = getWordAtPoint(e.target as HTMLElement, e.clientX, e.clientY);
        console.log("word", word, e.clientX, e.clientY);
        lookingForClick.value = false;
        if (!word) {
          return;
        }
        const elementContexts: string[] = [];
        let current: HTMLElement | null = parent;
        while (current) {
          if (current.getAttribute("data-define-context")) {
            elementContexts.push(current.getAttribute("data-define-context")!);
          }
          current = current.parentElement;
        }
        const paragraph = parent.innerText;
        definitionSource.value = {
          word,
          paragraph,
          element: parent,
          contexts: elementContexts,
        };
        definition.value = "";
        Watcher.watch(
          loadingDefinition,
          getDefinition(definitionSource.value, getContext).then((result) => {
            if (definitionSource.value?.word === word) {
              definition.value = result;
            }
          }),
        );
        popupOpen.value = true;
      };
      const onKey = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          lookingForClick.value = false;
        }
      };
      document.addEventListener("click", handler, { capture: true });
      document.addEventListener("keydown", onKey);
      return () => {
        document.removeEventListener("click", handler, { capture: true });
        document.removeEventListener("keydown", onKey);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookingForClick.value]);
  let buttonCls = lookingForClick.value ? "bg-red-500" : "bg-blue-200";
  buttonCls = twMerge("text-xs text-black py-0", buttonCls);
  return (
    <span>
      <Button
        className={buttonCls}
        onClick={() => {
          lookingForClick.value = true;
        }}
      >
        <BookOpenIcon className="w-4 h-4 inline-block mr-1" />
        {lookingForClick.value ? "Click on a word" : "Define"}
      </Button>
      <Popup showSignal={popupOpen}>
        <div className="text-sm">
          <div className="mb-4">
            Definition of <strong>"{definitionSource.value?.word}"</strong>
          </div>
          {definition.value && <Markdown className="ml-2" text={definition.value} />}
          {loadingDefinition.value && (
            <loadingDefinition.value.TimerMessage message="Creating definition..." />
          )}
        </div>
      </Popup>
    </span>
  );
}

async function getDefinition(definitionSource: DefinitionSource, getContext?: () => string) {
  const context = getContext ? getContext() : "";
  const resp = await llm.chat({
    title: "getDefinition",
    messages: [
      {
        role: "system",
        content: tmpl`
        You are a helpful assistant that defines words in a historical roleplaying game.

        You provide brief but helpful definitions of words along with the historical context of the word. Use simple language and short sentences, writing at a 5th grade level.

        Format your result in Markdown with multiple paragraphs.

        [[Note the context of the game: ${context}]]
        `,
      },
      {
        role: "user",
        content: tmpl`
        Define the word (or phrases around the word) "${definitionSource.word}"
        The word is from the paragraph:

        ${definitionSource.paragraph}

        (If "${definitionSource.word}" is a simple word then look for a complex word or idea near that word.)

        [[Note the context of "${definitionSource.word}" in the user interface:
          * ...${definitionSource.contexts}
        ]]
        `,
      },
    ],
  });
  return resp.content || null;
}
