// From: https://stackoverflow.com/questions/2444430/how-to-get-a-word-under-cursor-using-javascript
export function getWordAtPoint(elem: HTMLElement, x: number, y: number) {
  if (elem.nodeType === elem.TEXT_NODE) {
    const range = elem.ownerDocument.createRange();
    range.selectNodeContents(elem);
    let currentPos = 0;
    const endPos = range.endOffset;
    while (currentPos + 1 < endPos) {
      range.setStart(elem, currentPos);
      range.setEnd(elem, currentPos + 1);
      if (
        range.getBoundingClientRect().left <= x &&
        range.getBoundingClientRect().right >= x &&
        range.getBoundingClientRect().top <= y &&
        range.getBoundingClientRect().bottom >= y
      ) {
        // I guess .expand is an undocumented API
        (range as any).expand("word");
        const ret = range.toString();
        range.detach();
        return ret;
      }
      currentPos += 1;
    }
  } else {
    for (let i = 0; i < elem.childNodes.length; i++) {
      const child = elem.childNodes[i] as HTMLElement;
      const range = child.ownerDocument.createRange();
      range.selectNodeContents(child);
      if (
        range.getBoundingClientRect().left <= x &&
        range.getBoundingClientRect().right >= x &&
        range.getBoundingClientRect().top <= y &&
        range.getBoundingClientRect().bottom >= y
      ) {
        range.detach();
        return getWordAtPoint(child, x, y);
      } else {
        range.detach();
      }
    }
  }
  return null;
}

export function findClosestBlockLevelElement(element: HTMLElement) {
  let pos: HTMLElement | null = element;
  while (pos) {
    const style = window.getComputedStyle(element);
    if (
      style.display === "block" ||
      style.display === "flex" ||
      style.display === "grid" ||
      style.display === "table"
    ) {
      return pos;
    }
    pos = pos.parentElement;
  }
  return element;
}
