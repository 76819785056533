import { signal, effect } from "@preact/signals-react";

export function persistentSignal(name: string, defaultValue: any, options?: any) {
  if (typeof window === "undefined") {
    // On the server
    return signal(defaultValue);
  }
  const storage = options && options.sessionStorage ? window.sessionStorage : window.localStorage;
  if (!name || typeof name !== "string") {
    throw new Error("name must be a string");
  }
  let value = storage.getItem(`signal.${name}`);
  if (value) {
    value = JSON.parse(value);
    // value = deserialize(value);
  } else {
    value = defaultValue;
  }
  const s = signal(value);
  effect(() => {
    try {
      storage.setItem(`signal.${name}`, JSON.stringify(s.value));
    } catch (e) {
      console.error("Error saving signal", name, s.value, e);
      throw e;
    }
  });
  return s;
}
