import { Session } from "next-auth";
import { createContext, useContext } from "react";

export type User = {
  id: string;
  session: Session;
  email: string;
  image: string;
  inviteCode?: string;
  allowGpt35: boolean;
  allowGpt4: boolean;
  openaiKey?: string;
  openrouterKey?: string;
};

let _user: User | null = null;

export function getUser() {
  return _user;
}

export function setUser(user: User) {
  _user = user;
}

export const UserSessionContext = createContext<User | null | undefined>(undefined);

export const useUserSession = () => {
  const context = useContext(UserSessionContext);
  if (context === null) {
    return null;
  }
  if (context === undefined) {
    throw new Error("useUserSession must be used within a UserSessionProvider");
  }
  return context;
};
