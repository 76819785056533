import { useSignal } from "@preact/signals-react";
import React from "react";
import { twMerge } from "tailwind-merge";

const Button = ({
  onClick,
  clickedChildren,
  runningChildren,
  children,
  disabled,
  secondary,
  className,
  ...props
}: {
  onClick: () => Promise<any> | void;
  clickedChildren?: React.ReactNode | undefined;
  runningChildren?: React.ReactNode | undefined;
  children: React.ReactNode;
  disabled?: boolean;
  secondary?: boolean;
  className?: string | undefined;
  props?: any;
}) => {
  const clicked = useSignal(false);
  const running = useSignal(false);
  const cls = twMerge(
    "bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded-md mx-1",
    disabled ? "bg-gray-500 hover:bg-gray-400 cursor-not-allowed" : "",
    secondary ? "bg-blue-400 text-black hover:bg-gray-400" : "",
    className,
  );
  function doOnClick() {
    if (disabled) {
      return;
    }
    if (clickedChildren) {
      clicked.value = true;
      setTimeout(() => {
        clicked.value = false;
      }, 2000);
    }
    const result = onClick();
    if (result instanceof Promise) {
      running.value = true;
      result.finally(() => {
        running.value = false;
      });
    }
  }
  let c = children;
  if (clicked.value && clickedChildren) {
    c = clickedChildren;
  } else if (running.value) {
    if (runningChildren) {
      c = runningChildren;
    } else {
      // FIXME: add a spinner
      c = "Running...";
    }
  }
  return (
    <button className={cls} {...props} onClick={doOnClick} disabled={disabled}>
      {c}
    </button>
  );
};

export default Button;
