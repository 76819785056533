const KEY_NAME = "llm-user-keys";
const EXPIRATION_TIME = 1000 * 60 * 60; // 1 hour

export type UserKeys = {
  allowGpt35: boolean;
  allowGpt4: boolean;
  openaiKey?: string;
  openrouterKey?: string;
};

export async function getUserKeys(): Promise<UserKeys | undefined> {
  const resp = await fetch("/api/auth/llm-prefs");
  if (!resp.ok) {
    throw new Error("Error getting user keys");
  }
  const json = await resp.json();
  return json as UserKeys;
}

export async function getCachedUserKeys(): Promise<UserKeys | undefined> {
  const cached = localStorage.getItem(KEY_NAME);
  let data: any;
  if (cached) {
    try {
      data = JSON.parse(cached);
    } catch (e) {
      // Ignore
    }
  }
  if (data && data.created + EXPIRATION_TIME > Date.now()) {
    return data.userKeys;
  }
  const result = await getUserKeys();
  const newData = {
    userKeys: result,
    created: Date.now(),
  };
  localStorage.setItem(KEY_NAME, JSON.stringify(newData));
  return result;
}

export function purgeCachedUserKeys() {
  localStorage.removeItem(KEY_NAME);
}
