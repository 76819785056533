import React, { createElement } from "react";
import { marked } from "marked";

let jsdom: any = null;
let jsdomWindow: any = null;

if (typeof window === "undefined") {
  import("jsdom").then((module) => {
    jsdom = module;
    jsdomWindow = new jsdom.JSDOM();
  });
}

// See: https://marked.js.org/using_advanced
marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  breaks: true, // Makes line breaks into <br />
});

export function parseHtml(html: string, wrap = true) {
  if (wrap) {
    html = `<div>${html}</div>`;
  }
  let parser;
  if (typeof window === "undefined") {
    parser = new jsdomWindow.window.DOMParser();
  } else {
    parser = new DOMParser();
  }
  const doc = parser.parseFromString(html, "text/html");
  const el = doc.body.childNodes[0];
  return el;
}

// This is a hack to keep React from complaining about elements without keys
let elementKey = 0;

export function elementToReact(
  element: HTMLElement,
  callback?: (
    _el: HTMLElement,
    _tag: string,
    _props: any,
    _children: React.ReactNode[],
  ) => React.ReactNode,
): React.ReactNode {
  const MyNode = typeof window === "undefined" ? jsdomWindow.window.Node : Node;
  const tag = element.tagName.toLowerCase();
  const props: any = {};
  for (const attr of Array.from(element.attributes)) {
    if (attr.name === "class") {
      props.className = attr.value;
    } else if (attr.name === "style" && element.style.cssText) {
      const styles = element.style.cssText
        .split(";")
        .filter((style) => style.split(":")[0] && style.split(":")[1])
        .map((style) => [
          style
            .split(":")[0]
            .trim()
            .replace(/-./g, (c) => c.slice(1).toUpperCase()),
          style.split(":")[1].trim(),
        ])
        .reduce((acc, val) => ({ ...acc, [val[0]]: val[1] }), {});
      props.style = styles;
    } else {
      props[attr.name] = attr.value;
    }
  }
  const children: React.ReactNode[] = [];
  for (const child of Array.from(element.childNodes)) {
    if (child.nodeType === MyNode.TEXT_NODE) {
      children.push(child.textContent);
    } else if (child.nodeType === MyNode.ELEMENT_NODE) {
      children.push(elementToReact(child as HTMLElement, callback));
    }
  }
  let repl = null;
  if (callback) {
    repl = callback(element, tag, props, children);
  }
  if (repl === "") {
    repl = null;
  } else if (!repl) {
    props.key = elementKey++;
    repl = createElement(tag, props, ...children);
  }
  return repl;
}

export function markdownToElement(markdown: string) {
  if (typeof markdown !== "string") {
    console.warn("Got non-string markdown:", markdown);
    markdown = `\`\`\`\nUnexpected JSON:\n${JSON.stringify(markdown, null, 2)}\n\`\`\``;
  }
  const rendered = marked.parse(markdown);
  const el = parseHtml(rendered as string);
  return el;
}

export function markdownToReact(markdown: string) {
  const el = markdownToElement(markdown);
  return elementToReact(el as HTMLElement);
}

export function Markdown({ text, ...props }: { text: string; [key: string]: any }) {
  if (!text) {
    return null;
  }
  props.className = `${props.className || ""} unreset`;
  return <div {...props}>{markdownToReact(text)}</div>;
}

export function parseMarkdownList(markdown: string): string[] {
  const el = markdownToElement(markdown);
  return Array.from(el.querySelectorAll("li") as HTMLLIElement[]).map((li: HTMLLIElement) => {
    return li.innerText.trim();
  });
}
